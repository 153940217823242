// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.



//= require jquery3
//= require popper
//= require bootstrap-sprockets


import 'bootstrap'
// import "../stylesheets/application"
import "../../assets/stylesheets/application.scss"

//import './src/application.scss'
// import 'bootstrap'
// import './src/application.scss'

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"




Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"
//import { event } from 'jquery'
//the below  googlemaps code might be better served on the pages its in use.
window.initMap = function(...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event) 
}

$(window).ready(function(){
    initApp();  
});

const initApp = ()=>{
  langListener('ja');
  langListener('en')
}

const langListener = (languageID)=>{
  document.getElementById(languageID).addEventListener('click', (e)=>{
    setTimeout(() => {
      location.reload();
    }, 200);
  })
}
// window.I18n = require("../../../public/javascripts/i18n")
// require("../../../public/javascripts/translations")
import {Controller} from "stimulus"

export default class extends Controller{
  static targets = ["field", "map","longitude", "latitude"];
 

  connect() {
      if (typeof(google) != "undefined")[
        this.initMap()
      ]
      
  }
  
  initMap(){
    
    var points;
    let marker;
    let lat;
    let long;
    let markers = [];
    let filters = {"type": "","prefecture": "", "language":""};
    let lastFilters= {"type": "","prefecture": "", "language": ""};
    const searchItems = ['type','prefecture','language'];
     let map = new google.maps.Map(document.getElementById("map"), {
      center: new google.maps.LatLng(39.0981528,139.3587947),
      disableDefaultUI: true,
      zoom: 6
    })
   
    points = $("#mycontainer").data('source')
    addMarkers(points);
    for(let i = 0; i < searchItems.length; i ++){
      document.getElementById(searchItems[i]).addEventListener('change',(e)=>{
        filters[searchItems[i]] = document.getElementById(searchItems[i]).value;
        let selectTag = filters[searchItems[i]];
        let lastInterest = lastFilters[searchItems[i]];
        if ( lastInterest != selectTag){
          clearMarkers();
          markers = [];
          lastFilters[searchItems[i]] = selectTag;
          let filteredPoints =filterPoints();
          
          addMarkers(filteredPoints);
           
        }
        // else if(selectTag == "" && lastInterest != selectTag)
        // {
        //   clearMarkers();
        //   markers= [];
        //   addMarkers(points);
        //   lastFilters[searchItems[i]] = selectTag;
        // }
      })
    }
   function filterPoints(){
     let meow = points.filter(x=> (x.type === parseInt(filters['type']) ||lastFilters['type'] == "") && (x.prefecture === parseInt(filters['prefecture']) ||lastFilters['prefecture'] == "") && 
     (x.language === parseInt(filters['language']) ||  lastFilters['language'] == ""));
     return meow;
   }
    function changeMarkers(){

    }
    function clearMarkers(){
      for(var i=0; i< markers.length; i++){
        markers[i].setMap(null);
    }

    }
    function toggleBounce() {
      if (marker.getAnimation() !== null) {
        marker.setAnimation(null);
      } else {
        marker.setAnimation(google.maps.Animation.BOUNCE);
      }
    }
    function addMarkers(points){
      let infowindow = new google.maps.InfoWindow({
        maxWidth:600,
        minWidth:200
      });
      for (let i = 0; i < points.length; i ++){
        lat = points[i].lat;
        long = points[i].long;
  
        let newmarker = new google.maps.Marker({
          map: map,
          animation: google.maps.Animation.DROP,
          position: { lat: parseFloat(lat), lng: parseFloat(long) },
        });
        
        
        const contentString =
        '<div id="content">' +
        '<div id="siteNotice">' +
        `<h4 id="firstHeading" class="firstHeading" style="text-align:center; "> ${points[i].title}</h4>` +
        `<img  src="${points[i].image}" style="width:200px;height:100px; float: left;margin-right: 10px;>` +
  
        '<div id="bodyContent" class="container-fluid">' +
        `<p>${points[i].description}</p>` +
        
        '</div>' +
       
        '<div>' +
        `<p> Location:${points[i].lat}, ${points[i].long}  </p>` +
        "</div>";
        
      google.maps.event.addListener(newmarker,'click', (function(newmarker, i) {
        return function(){
          infowindow.setContent(contentString);
          infowindow.open(map, newmarker);
          
      }
      
      // marker.addListener("click", () => {
      //   infowindow.open(map, marker);
      // });
    })(newmarker,i));
    markers.push(newmarker);
    }
  
  }
  };
   
}






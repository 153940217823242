import { makeArray } from "jquery";
import {Controller} from "stimulus"

export default class extends Controller{
  static targets = ["field", "map","longitude", "latitude"];
  

  connect() {
       if (typeof(google) != "undefined")[
        this.initMap()
     ]
      
  }
  
  initMap(){
    
 
    let marker;
    let lat;
    let long;
    let title;
    long = parseFloat($("#longitude").val());
    lat = parseFloat($("#latitude").val());
    title = $("#image_url").val();
     let map = new google.maps.Map(document.getElementById("map"), {
      center: new google.maps.LatLng(lat ||35.52796448718265, long || 139.8775390625),
      disableDefaultUI: true,
      zoom:  (title !=  "" ? 15 : 5) 
    })
    //Could not add an ID to the below form element so, in order to add a listenter to it, I used the parent element, then attached a listener to the child
    let descja = document.getElementById('descja');
    let descen = document.getElementById('descen');
    let language =  document.getElementById("language1");
       let child =language.childNodes[1];
       child.addEventListener('change',(e)=>{
       if(child.value == '0'){
        descja.style.display = 'flex';
        descja.disabled = false;
        descen.style.display = 'none';
        descen.disabled = true;

       } 
       else if(child.value == '1'){
        descja.style.display = 'none';
        descja.disabled = true;
        descen.style.display = 'flex';
        descen.disabled = false;
       }
    })
     //this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
    // this.autocomplete.bindTo('bounds', this.map)
    // this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
    // this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
    // this.marker = new google.maps.Marker({
    //   map: this.map,
    //   anchor: new google.maps.Point(38.3140851,138.6779312)
    // })
   
    
    $("#longitude").val(long);
 
    marker = new google.maps.Marker({
      map: map,
      draggable: true,
      animation: google.maps.Animation.DROP,
      position: {lat: lat ||35.52796448718265 , lng: long || 139.8775390625},
    });
    
     function updateMarkerPosition(latLng) {
      $(document).ready(function(){$("#longitude").val(latLng.lng);});
      $(document).ready(function(){$("#latitude").val(latLng.lat);});
    }
    marker.setAnimation(google.maps.Animation.BOUNCE);
    marker.addListener("click", toggleBounce);
    google.maps.event.addListener(marker, 'dragend', function() {
     
      updateMarkerPosition(marker.getPosition());
      marker.setAnimation(null);
      
    });
    marker.getPosition()

    function toggleBounce() {
      if (marker.getAnimation() !== null) {
        marker.setAnimation(null);
      } else {
        marker.setAnimation(google.maps.Animation.BOUNCE);
      }
    }
    
  };

placeChanged(){
  let place = this.autocomplete.getPlace()
  if(!place.geometry){
    window.alert('There aaint shit')
    return
  }
  if(place.geometry.viewport)
  {
    this.map.fitBounds(place.geometry.viewport)
  }
  else
  {
    this.map.setCenter(place.geometry.location)
    this.map.setZoom(17)
  }
  this.marker.setPosition(place.geometry.location)
  this.marker.setVisible(true)
  this.locationTarget.value = "meow"
  this.latitudeTarget.value = place.geometry.location.lat()
  this.longitudeTarget.value = place.geometry.location.lng()
  }
}
  





